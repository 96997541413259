import * as React from 'react'
import styled from 'styled-components'

interface Props {
  html: string
}
export const Markdown: React.FC<Props> = (props): JSX.Element => {
  return (
    <StyledComponent>
      <div className='html' dangerouslySetInnerHTML={{ __html: props.html }} />
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .html {
    font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
      Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    margin: 24px 0;
    word-break: break-word;

    .language-text {
      color: #000;
      background: #dddddd;
      padding: 0 8px;
    }
  }
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 24px 0;
  }
  h2 {
    font-size: 24px;
    font-weight: 800;
    margin: 40px 0 16px;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 6px;

    &::before {
      content: '# ';
      color: #2cb696;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 800;
    margin: 32px 0 12px;
  }
  em {
    font-style: oblique;
  }
  strong {
    font-weight: 800;
  }
  a {
    color: #2cb696;
  }
  img {
    max-width: 100%;
  }

  /**
  * @'gatsby-remark-code-titles'の拡張
  */
  .gatsby-code-title {
    background: #2e96b5;
    color: #eee;
    padding: 4px 8px;
    margin-top: 8px;
    font-size: 0.8em;
    line-height: 1;
    font-weight: bold;
    display: table;
    border-radius: 4px 4px 0 0;
  }
  .gatsby-highlight {
    margin-bottom: 8px;
  }
  pre[class*='language-'] {
    background-color: #1c1b1b;
    display: block;
    padding-right: 1rem;
    padding-left: 2rem;
    border-radius: 0 4px 4px 4px;
    margin: 0;
  }
  pre[class*="language-"].line-numbers {
    padding: 0.6em;
    padding-left: 2.8em;
  }
  .line-numbers-rows {
    padding: 0.6em 0;
    padding-left: 4px;
  }
  code[class*='language-'],
  pre[class*='language-'] {
    font-size: 0.9em;
    color: #fff;
  }
  :not(pre)>code[class*='language-text'] {
    font-size: 1em;
    padding: 2px 10px 3px 12px;
    margin: 0 4px;
    background-color: #3c3c3c;
  }
  .gatsby-highlight-code-line {
    background: #545454;
    display: block
  }

  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
      margin: 20px 0 8px;
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
  }
`
