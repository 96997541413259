import * as React from 'react'
import { useSelector } from 'react-redux'
import { State } from '@/store'
import styled from 'styled-components'
import { GlobalStyle } from '@/styles/global'
import Profile from '@/components/Profile'
import { ProfileTabs } from '@/components/ProfileTabs'
import Loading from '@/layouts/Loading'
import Footer from '@/components/Footer'

interface Props {
  children: JSX.Element | JSX.Element[]
}
export const Layout: React.FC<Props> = (props): JSX.Element => {
  const sessionStore = useSelector((state: State) => state.session)

  return (
    <StyledComponent>
      <GlobalStyle />
      {!sessionStore.session && <Loading />}
      <main className='main'>
        <div className='profile-wrap'>
          <Profile />
        </div>
        <ProfileTabs />
        <div className='content'>{props.children}</div>
      </main>
      <Footer />
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .main {
    width: 100%;
    margin: auto;

    .profile-wrap {
      margin: auto;
      width: 720px;
      padding: 32px 0;
    }

    .content {
      margin: auto;
      width: 720px;

      .blog-item {
        margin-bottom: 16px;
      }
    }

    @media screen and (max-width: 1024px) {
      .profile-wrap {
        width: 100%;
        padding: 24px 0;
      }
      .content {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 480px) {
    }
  }
`
