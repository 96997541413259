import * as React from 'react'
import styled from 'styled-components'

interface Props {}
const ProfileBadge: React.FC<Props> = (): JSX.Element => {
  return (
    <StyledComponent>
      <span className='badge' />
    </StyledComponent>
  )
}
export default ProfileBadge

const StyledComponent = styled.div`
  .badge {
    position: relative;
    display: flex;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      background-color: #2cb696;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 6px;
      height: 4px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 6px;
  }
  @media screen and (max-width: 768px) {
    .badge {
      width: 20px;
      height: 20px;

      &::before {
        width: 15px;
        height: 15px;
      }
      &::after {
        top: 8px;
        left: 6px;
        width: 5px;
        height: 2px;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
