import * as React from 'react'
import styled from 'styled-components'
import ProfileBadge from '@/components/atoms/badges/ProfileBadge'
import { IconLink } from '@/components/atoms/images/IconLink'
import { ProfileImage } from '@/components/atoms/images/ProfileImage'
const twitter_path = require('@/images/twitter-brands.svg')
const github_path = require('@/images/github-brands.svg')

interface Props {}
const Profile: React.FC<Props> = (): JSX.Element => {
  return (
    <StyledComponent>
      <div className='profile-image'>
        <ProfileImage />
      </div>
      <div className='profile-body'>
        <div className='profile-body__name'>
          <span>taiki.shiino</span>
          <ProfileBadge />
        </div>
        <div className='profile-body__text'>
          テックブログ・製作物・経歴など
        </div>
        <div className='icon-wrap'>
          <div className='icon-wrap__icon'>
            <IconLink
              href='https://twitter.com/buena926'
              src={twitter_path}
              alt='twitter'
            />
          </div>
          <div className='icon-wrap__icon'>
            <IconLink
              href='https://github.com/taikishiino'
              src={github_path}
              alt='github'
            />
          </div>
        </div>
      </div>
    </StyledComponent>
  )
}
export default Profile

const StyledComponent = styled.div`
  display: flex;

  .profile-image {
    width: 88px;
    height: 88px;

    .gatsby-image-wrapper {
      width: 100%;
      border-radius: 88px;
    }
  }

  .profile-body {
    margin-left: 32px;
    font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
      Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

    &__name {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
    }
    &__text {
      font-size: 16px;
      margin: 16px 0;
    }
  }

  .icon-wrap {
    width: 100%;
    &__icon {
      display: inline-block;
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 24px;

    .profile-image {
      width: 72px;
      height: 72px;
      .gatsby-image-wrapper {
        border-radius: 72px;
      }
    }
    .profile-body {
      margin-left: 24px;
      &__name {
        font-size: 20px;
        margin: 0;
      }
      &__text {
        font-size: 14px;
        margin: 12px 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .profile-image {
      width: 48px;
      height: 48px;
      .gatsby-image-wrapper {
        border-radius: 48px;
      }
    }
    .profile-body {
      &__name {
        font-size: 16px;
      }
      &__text {
        font-size: 12px;
        margin: 8px 0;
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
