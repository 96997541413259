import * as React from 'react'
import styled from 'styled-components'

interface Props {
  href: string
  src: any
  alt?: string
}
export const IconLink: React.FC<Props> = (props): JSX.Element => {
  return (
    <StyledComponent href={props.href} target='_blank' rel='noopener'>
      <img src={props.src} alt={props.alt || ''} />
    </StyledComponent>
  )
}

const StyledComponent = styled.a`
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: 768px) {
  }
`
