import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '@/layouts'
import SEO from '@/components/seo'
import { Markdown } from '@/components/Markdown'

interface Props {
  data: any
}
const Page: React.FC<Props> = (props): JSX.Element => {
  const markdown = props.data.markdownRemark || {}
  const title = markdown.frontmatter.title || ''
  const discription = markdown.frontmatter.discription || ''
  const name = markdown.frontmatter.name || null

  return (
    <Layout>
      <SEO title={title} description={discription} />
      <StyledComponent>
        <Markdown html={markdown.html} />
        {name === 'request_job' && (
          <iframe
            src='https://docs.google.com/forms/d/e/1FAIpQLSfNomCS1C7MQZbVGK1b-g9T8ErkWI4qM4mR-nD7PisWQUuPNw/viewform?embedded=true'
            style={{ border: 0, width: '100%', height: '840px' }}
            scrolling='no'
          >
            読み込んでいます...
          </iframe>
        )}
      </StyledComponent>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query PageQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        discription
        name
      }
    }
  }
`

const StyledComponent = styled.div`
  padding: 0 12px;

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
  }
`
