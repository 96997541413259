import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Location } from '@reach/router'

interface Props {}
interface LocationProps {
  location: any
}
export const ProfileTabs: React.FC<Props> = (): JSX.Element => {
  const tabs = (
    <Location>
      {(props: LocationProps) => {
        const isBlog = props.location.pathname === '/'
        const isProducts = props.location.pathname.indexOf('products') > -1
        const isRequestJob = props.location.pathname.indexOf('request_job') > -1

        return (
          <ul>
            <li>
              <Link to='/' className={isBlog ? 'tab tab__active' : 'tab'}>
                ブログ
              </Link>
            </li>
            <li>
              <Link
                to='/products'
                className={isProducts ? 'tab tab__active' : 'tab'}
              >
                プロダクト
              </Link>
            </li>
            <li>
              <Link
                to='/request_job'
                className={isRequestJob ? 'tab tab__active' : 'tab'}
              >
                仕事依頼
              </Link>
            </li>
          </ul>
        )
      }}
    </Location>
  )
  return <StyledComponent>{tabs}</StyledComponent>
}

const StyledComponent = styled.nav`
  display: flex;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  height: 56px;

  ul {
    display: flex;
    align-items: center;
    width: 720px;
    height: 100%;
    margin: auto;
    list-style-type: none;
    padding: 0;

    li {
      white-space: nowrap;

      .tab {
        font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
          'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ',
          Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
        font-size: 18px;
        padding: 18px 24px;
        color: #000;
        text-decoration: none;

        &__active {
          color: #2cb696;
          font-weight: 900;
          border-bottom: 3px solid #2cb696;
          pointer-events: none;
          cursor: default;
        }
        &:hover {
          background: rgba(44, 182, 150, 0.2);
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    height: 48px;
    ul {
      width: 100%;
      li {
        .tab {
          padding: 16px 22px;
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: 40px;
    ul {
      li {
        .tab {
          padding: 12px 20px;
          font-size: 12px;

          &__active {
            border-bottom: 2px solid #2cb696;
          }
          &:hover {
            background: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
